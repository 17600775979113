import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "../styles/hero.css"
import cf_video from "../images/cold_flow_2023.mp4"

const VideoBackground = () => {
  return (
    <section className="hero-section" id="hero-id">
      <div className="video-background">
        <video autoPlay loop muted style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }}>
          <source src={cf_video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className= "overlay">
                <div className="hero-title">
                    <div>
                        <h1>LIQUID ROCKETRY LAB</h1>
                        <ul>
                            <li><a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=GL3SSSK45D4X2&source=url" target= "_blank" rel="noopener noreferrer">Support Us</a></li>
                            <li><a href="https://forms.gle/8WUuyYxkBPVbDVbNA" target= "_blank" rel="noopener noreferrer">Join The Team</a></li>
                        </ul>
                    </div>
                    
                </div>
                <AnchorLink to= "#about">
                    <svg className="arrows">
                        <path className="a1" d="M0 0 L30 32 L60 0"></path>
                        <path className="a2" d="M0 20 L30 52 L60 20"></path>
                        <path className="a3" d="M0 40 L30 72 L60 40"></path>
                    </svg>  
                </AnchorLink>
                
            </div>
      </div>
    </section>
  )
}

export default VideoBackground;
